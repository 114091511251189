import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "AI-Powered Standard Operating Procedures",
  },
  "header": {
    "title": <><span>AI-Powered</span> Standard Operating Procedures</>,
    "description": "Systematize the expertise of your top performers and turn new hires into instant experts.",
  },
  "main": [
    {
      "title": "Interactive Standard Operating Procedure (SOP)",
      "description": "The original static content and the converted interactive content",
      "apps": [
        "/apps/66b06d39afb8636dcb8ee951", "/apps/5e841cb875b8a92a15455b0f"],
    },
    {
      "title": "The Future of Sales",
      "description": <><span>59%</span> less employee turnover from companies who practice a more engaging workforce.</>,
      "loom": "https://www.loom.com/embed/a2784a834b494e0187bf7affa98e4b4c?sid=e607b216-59b5-4d49-a315-dd425e1b9bf6"
    },
  ],
}

const StandardOperatingProcedures = () => {
  return (
    <Template content={content} />
  )
};
export default StandardOperatingProcedures;
