import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Interactive and Personalized E-Learning Modules",
  },
  "header": {
    "title": <><span>Interactive</span> and Personalized E-Learning Modules</>,
    "description": "Design personalized learning journeys and micro-learning modules effortlessly with our No-Code platform for Interactive E-Learning.",
    "app": "/apps/5ebabbfcae527b799ba7f9d6"
  },
  "main": [
    {
      "title": <>Sage Guideflows</>,
      "app": "/apps/5eaf2ef3d0d0442cb6bcab7e"
    },
    {
      "title": <>3-Step Mindset Reset</>,
      "app": "/apps/5ed97e190e5e7f2733a5dce5"
    },
    {
      "title": <>Founders Academy Essentials Workbook</>,
      "app": "/gordondaugherty-founders-academy-essentials-workbook"
    },
    {
      "title": <>DivInc Mentor Meetups</>,
      "app": "/apps/5e9620847bca6007bbaa4a73"
    },
    {
      "title": <>Humanistic Marketing Amidst a Crisis</>,
      "app": "/apps/5eaf89c7d0d0442cb6bcab7f"
    }
  ],
  "caseStudies": [
    "lisabeth-thomas-outwitting-fear-course",
    "impact-sales-sales-decision-guides"
  ]
}

const ELearning = () => {
  return (
    <Template content={content} />
  )
};
export default ELearning;
