import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "World Logic Forum",
  },
  "header": {
    "title": "World Logic Forum",
    "description": "Learn more about the United Nations World Logic Day",
    "youtube": "https://www.youtube.com/embed/z7Wt25nWu-c?si=5QiOLaeJH90oCgRY",
    "cta": {
      "title": "Learn more!",
      "link": "https://worldlogicday.com/"
    }
  },
  "main": [
  ],
}

const WorldLogicForum = () => {
  return (
    <Template content={content} />
  )
};
export default WorldLogicForum;
