import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "The Future of Content is Interactive and AI-Powered",
  },
  "header": {
    "title": <>The Future of Content is <span>Interactive</span> and AI-Powered</>,
    "description": "Discover how interactive content is revolutionizing the digital landscape and shaping the future of personalized online engagement.",
    "youtube": "https://www.youtube.com/embed/YOvuO7TMILE"
  },
  "main": [
    {
      "title": <>Convert a Word Doc into a Decision Tree</>,
      "image": "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/25934987be22135c2de881756f5b5ca2/worddoctowebapp.png",
    },
    {
      "title": <>Convert a Spreadsheet into a Directory</>,
      "image": "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/d20fa1c4c493c06ffaad4f458f3c548c/spreadsheettowebapp.png",
    },
    {
      "title": "Decision Tree Example",
      "description": "The original static content and the converted interactive content",
      "apps": [
        "/apps/66ae4ed0afb8636dcb8ece61", "/colorado_informed-how-to-vote-in-colorado"],
      "cta": ""
    },
    {
      "title": "Assessment Example",
      "description": "The original static content and the converted interactive content",
      "apps": [
        "/apps/66b06a24afb8636dcb8ee646", "/theacaciacompany-dei-business-assessment"],
      "cta": ""
    },
    {
      "title": "HR Handbook Example",
      "description": "The original static content and the converted interactive content",
      "apps": [
        "/apps/66d3352f95166bc0e11493c0", "/apps/64ce7bc129bc980dbe5a16b9"],
    },
    {
      "title": "Interactive Standard Operating Procedure (SOP)",
      "description": "The original static content and the converted interactive content",
      "apps": [
        "/apps/66b06d39afb8636dcb8ee951", "/apps/5e841cb875b8a92a15455b0f"],
    },
  ],
}

const InteractiveContent = () => {
  return (
    <Template content={content} />
  )
};
export default InteractiveContent;
