import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "AI-Powered Sales Guides",
  },
  "header": {
    "title": <><span>AI-Powered</span> Sales Guides</>,
    "description": "Discover the next generation of FAQs",
    "app": "/apps/6526afa7db17013150f049d2"
  },
  "main": [
  ],
}

const SalesGuides = () => {
  return (
    <Template content={content} />
  )
};
export default SalesGuides;
