import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "AI-Powered Chatbots",
  },
  "header": {
    "title": <><span>AI-Powered</span> Chatbots</>,
    "description": "Create powerful chatbots to answer questions based on the training and answers you provide.",
    "app": "/apps/66b25fede559423de19e7020?showchatbot=true"
  },
  "main": [
    {
      "title": "FAQ Chatbots",
      "description": "Leverage AI-powered chatbots to handle frequently asked questions with interactive content.",
      "tabs": [
        {
          "title": "Chatbot FAQ",
          "description": "An AI-driven chatbot designed to provide answers to frequently asked questions.",
          "app": "/apps/66b25fede559423de19e7020?showchatbot=true"
        },
        {
          "title": "Interactive FAQ",
          "description": "An interactive FAQ experience for enhanced user engagement.",
          "app": "/apps/66b25fede559423de19e7020"
        }
      ]
    },
    {
      "title": "HR Handbook Chatbots",
      "description": "Deploy chatbots to assist with HR handbook inquiries and interactive guidance.",
      "tabs": [
        {
          "title": "Chatbot HR Handbook",
          "description": "A chatbot designed to assist with HR handbook questions and information.",
          "app": "/apps/64ce7bc129bc980dbe5a16b9?showchatbot=true"
        },
        {
          "title": "Interactive HR Handbook",
          "description": "An interactive HR handbook experience for enhanced employee support.",
          "app": "/apps/64ce7bc129bc980dbe5a16b9"
        }
      ]
    }
  ]
}

const Chatbots = () => {
  return (
    <Template content={content} />
  )
};
export default Chatbots;
