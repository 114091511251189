import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Interactive AI-Powered HR Handbooks"
  },
  "header": {
    "title": <>Interactive <span>AI-Powered</span> HR Handbooks</>,
    "description": "Utilize AI to create curated event planning tools tailored to your audience's needs.",
    "app": "/apps/64ce7bc129bc980dbe5a16b9"
  },
  "main": [
    {
      "title": "Chatbot HR Handbook",
      "description": "A chatbot designed to assist with HR handbook questions and information.",
      "app": "/apps/64ce7bc129bc980dbe5a16b9?showchatbot=true"
    },
  ]
}

const HRHandbooks = () => {
  return (
    <Template content={content} />
  )
};
export default HRHandbooks;
