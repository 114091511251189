import React, { useState } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { NavLink } from "react-router-dom";
import Header from "../../styledhtml/Header";
import CTA from "../../styledhtml/CTA";
import Section from "../../styledhtml/Section";
import Main from "../../styledhtml/Main";
import Testimonials from "../Testimonials";
import Content from "../Content";
import SectionHorizontal from "../../styledhtml/SectionHorizontal";
import Logos from "../Logos";
import { device } from "../../submodules/logictry_common/src/utils/device";
import Constants from '../../submodules/logictry_config/constants';
import CaseStudies from "../../containers/CaseStudies/casestudies";
import Sitemap from '../../components/Navbar/sitemap.json';

const Arrow = styled.div`
  @media ${device.laptop} {
    display: none;
  }
`;

const TabHeader = styled.div`
  max-width: 28rem;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  @media ${device.laptop} {
    flex-direction: row;
    display: none;
  }
  p {
    font-size: 1.1rem;
    color: #505256;
  }
`;
const Select = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
  }
`;
const SelectField = styled.select`
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  display: block;
  font-weight: 500;
  min-width: 240px;
`;
const SelectDescription = styled.div`
  display: none;
  @media ${device.laptop} {
    display: unset;
  }
`;

const TabButton = styled.div`
  flex: 1;
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  @media ${device.laptop} {
    padding: 0.5rem 1rem;
  }
  border-left: ${(props) => (props.active ? '4px solid #1ebd53' : '4px solid transparent')};
  color: ${(props) => (props.active ? 'unset' : '#999')};
  h3 {
    font-weight: ${(props) => (props.active ? '500' : '500')};
    font-size: 1.3rem;
    @media ${device.laptop} {
      font-size: 1.1rem;
    }
    margin-bottom: 0.25rem;
  }
  div {
    color: ${(props) => (props.active ? '#505256' : '#999')};
    font-size: 1rem;
    @media ${device.laptop} {
      font-size: unset;
    }
  }
`;
const CaseStudySection = styled.section`
  margin: 0 1rem;
  overflow: hidden;
  max-width: unset;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 4rem 0rem;
  @media ${device.laptop} {
    padding: 2rem 0rem;
  }
  > div:last-child {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem;
  }
  a {
    width: 320px;
    display: block;
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
    > div > div {
      padding: 0 1rem;
    }
  }
  text-align: center;
  img {
    height: 180px;
    width: 320px;
    object-fit: cover;
    border-radius: 0.5rem;
  }
`;

const Tab = ({ children, active, onClick }) => (
  <TabButton active={active} onClick={onClick}>
    {children}
  </TabButton>
);

const GetSection = (s, i) => {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const handleSelectChange = (event) => {
    setActiveTab(s.tabs.findIndex(({ title }) => title === event.target.value));
  };
  const SectionToDisplay = SectionHorizontal//s.apps && Section || SectionHorizontal;
  const options = s.tabs && s.tabs.map(({ title }) => title) || [];
  return (
    <SectionToDisplay key={s.title}>
      {s.tabs && <>
        <Select>
          <SelectField value={s.tabs[activeTab].title} onChange={handleSelectChange}>
            {options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </SelectField>
          <SelectDescription>{s.tabs[activeTab].description}</SelectDescription>
        </Select>
        <TabHeader>
          <h2>{s.title}</h2>
          <p>{s.description}</p>
          {s.link && (s.link.startsWith('http') && <a href={s.link} target="_blank"><button>Learn More →</button></a>
          || <NavLink to={s.link}><button>Learn More →</button></NavLink>)}
          <br></br>
          {s.tabs.map(({ title, description }, i) => (
            <Tab active={activeTab === i} onClick={() => handleTabClick(i)}>
              <h3>{title}</h3>
              <div>{description}</div>
              {/* {activeTab === i && <div>{description}</div>} */}
            </Tab>
          ))}
        </TabHeader>
      </>
      || <div>
        <h2>{s.title}</h2>
        <p>{s.description}</p>
        {s.link && (s.link.startsWith('http') && <a href={s.link} target="_blank"><button>Learn More →</button></a>
        || <NavLink to={s.link}><button>Learn More →</button></NavLink>)}
      </div>}
      {s.apps && <Arrow>
        <img style={{ maxWidth: 32, transform: i % 2 === 1 && 'scaleX(-1)' }} src="https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/131b4fe754d0c956932e4bd9cefcd16a/downarrow.png"></img>
      </Arrow>}
      {s.tabs && Content(s.tabs[activeTab]) || Content(s)}
    </SectionToDisplay>
  )
}

const Template = ({ content: { meta, header, main, caseStudies } }) => {
  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
      </Helmet>
      <Navbar />
      <Header>
        <div>
          <h1>{header.title}</h1>
          <p>{header.description}</p>
          <a href={header.cta && header.cta.link || "https://app.logictry.com/register"} target="_blank">
            <button>{header.cta && header.cta.title || "Get Started for FREE"}</button>
          </a>
        </div>
        {Content(header, true)}
      </Header>
      <Logos />
      <Main>
        {main.map((s, i) => GetSection(s, i))}
        <Testimonials />
        {caseStudies && <CaseStudySection>
          <div>
            <h2>Case Studies</h2>
          </div>
          <div>
            {caseStudies.map((url) => {
              console.log('case')
              const { image, title } = CaseStudies[url];
              return <NavLink key={url} to={`${Sitemap.CaseStudies}/${url}`}>
                <div>
                  <img src={image} alt={title} />
                  <div>{title}</div>
                </div>
              </NavLink>
            })}
          </div>
        </CaseStudySection>}
        <CTA>
          <div>
            <div>
              <h2>Let us show you what is possible</h2>
            </div>
            <a href="https://calendly.com/logictry/30min" target="_blank">Book Demo Call</a>
          </div>
        </CTA>
      </Main>
      <Footer />
    </>
  )
};
export default Template;
