import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { NavLink } from "react-router-dom";
import Header from "../../styledhtml/Header";
import CTA from "../../styledhtml/CTA";
import Section from "../../styledhtml/Section";
import Main from "../../styledhtml/Main";
import Testimonials from "../Testimonials";
import Content from "../Content";
import SectionHorizontal from "../../styledhtml/SectionHorizontal";
import Logos from "../Logos";
import { device } from "../../submodules/logictry_common/src/utils/device";

const Arrow = styled.div`
  @media ${device.laptop} {
    display: none;
  }
`


const FullScreenApp = () => {
  return (
    <>
      <Helmet>
        {/* <title>{meta.title}</title> */}
      </Helmet>
      <Navbar />
      <main style={{ width: '100%', maxWidth: 1200, margin: '8rem auto' }}>
        <iframe style={{ width: '100%' }} src="https://app.logictry.com/apps/66d0dfffe12117638c7aa840?allowfullscreen=false&autoscroll=false"></iframe>
      </main>
    </>
  )
};
export default FullScreenApp;
