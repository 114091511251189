import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Interactive AI-Powered Smart FAQs",
  },
  "header": {
    "title": <>Interactive <span>AI-Powered</span> Smart FAQs</>,
    "description": "Explore a revolutionary way to interact with FAQs, powered by AI technology.",
    "app": "/apps/66b25fede559423de19e7020?onclickshowfullscreen=false&onlyscrollfullscreen=false",
  },
  "main": [
    {
      "title": "AI-Driven Chatbot",
      "description": "Transform your FAQ into a dynamic, interactive chatbot experience.",
      "app": "/apps/66b25fede559423de19e7020?onclickshowfullscreen=false&onlyscrollfullscreen=false&showchatbot=true",
    },
  ],
  "caseStudies": [
    "peloton-u-smart-faq"
  ]
}

const SmartFAQs = () => {
  return (
    <Template content={content} />
  )
};
export default SmartFAQs;
