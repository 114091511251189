import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "AI-Powered Product Catalogs",
  },
  "header": {
    "title": <><span>AI-Powered</span> Product Catalogs</>,
    "description": "Use AI to build product recommendation guides",
    "app": "/apps/66d6f77a6fca370c65f3f3f9",
  },
  "main": [
  ],
}

const ProductCatalogs = () => {
  return (
    <Template content={content} />
  )
};
export default ProductCatalogs;
