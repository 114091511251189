import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Interactive Guided Selling for Faster Sales Velocity",
  },
  "header": {
    "title": <>Interactive <span>Guided Selling</span> for Faster Sales Velocity</>,
    "description": "We turn a complex sales process into an easy button.",
    "youtube": "https://www.youtube.com/embed/941kI-S6ZhE"
  },
  "main": [
    {
      "title": <>Interactive <span>AI-Powered</span> Sales Configurators</>,
      "description": "DEMO: Try typing the words: Data Logging 8106",
      "app": "/apps/5e41733f18397e3927db88ee"
    },
    {
      "title": "Interactive Sales Playbooks",
      "description": <><span>93%</span> of marketers agreed that interactive content is more successful at educating buyers.</>,
      "loom": "https://www.loom.com/embed/a2784a834b494e0187bf7affa98e4b4c?sid=e607b216-59b5-4d49-a315-dd425e1b9bf6"
    },
  ],
}

const SalesTeams = () => {
  return (
    <Template content={content} />
  )
};
export default SalesTeams;
