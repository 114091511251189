import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { device } from "../../submodules/logictry_common/src/utils/device";

const Wrapper = styled.div`
  padding: 4rem 1rem;
  @media ${device.laptop} {
    padding: 2rem 0rem;
  }
  text-align: center;
  h2 {
    margin-bottom: 2rem;
  }
  .scrollable-logo-section {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding: 10px;
    max-width: 1440px;
    margin: auto;
    height: 60px;
  }

  .logo-container {
    display: flex;
    align-items: center;
    overflow: auto;
    padding-bottom: 40px;
  }

  .logo-item {
    margin: 0 1rem;
    max-height: 2.5rem;
  }

  .scroll-icon {
    cursor: pointer;
    font-size: 2rem;
    color: #333;
    margin: 0 2rem;
  }
`;

const ScrollableLogoSection = ({ logos }) => {
  const scrollContainerRef = useRef(null);
  const speed = 1 / 2; // pixels per frame

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    let scrollAmount = 0;

    const scrollContent = () => {
      scrollAmount += speed;
      if (scrollAmount >= scrollContainer.scrollWidth / 2) {
        scrollAmount = 0;
      }
      scrollContainer.scrollLeft = scrollAmount;
      requestAnimationFrame(scrollContent);
    };

    requestAnimationFrame(scrollContent);
  }, []);

  return (
    <div className="scrollable-logo-section">
      {/* <div className="scroll-icon" onClick={() => scroll('left')}>
        &lt;
      </div> */}
      <div className="logo-container" ref={scrollContainerRef}>
        {logos.concat(logos).map((logo, index) => (
          <img key={index} src={logo} alt={`logo-${index}`} className="logo-item" />
        ))}
      </div>
      {/* <div className="scroll-icon" onClick={() => scroll('right')}>
        &gt;
      </div> */}
    </div>
  );
};


// Example logos (replace with your own)
const logos = [
  "https://logictrystatic.s3.amazonaws.com/images/acacia_logo.png",
  "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/06e3289bc520cb0512e9e1c0fc90444e/strategydna.png",
  "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/6761ebb8dd9d03bd528234c2e7c0a040/pelotonU-logo2.svg",
  "https://logictrystatic.s3.amazonaws.com/images/athleta_logo.png",
  "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/dd5fc5511022dee1f9125cb160fafd6a/ni.png",
  "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/cfffbe63690095a7c9f29641c59c7fb7/rsm.png",
  "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/7372d09012d854bded7cd3b187c29f88/ymca.png",
  "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/aa7dfc6e2d75106a58cb9a7234f0e5be/ci.png",
  "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/f8b5313b8a7c73178c13bc5c5c50e0b7/asg.png",
  "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/4349f2c15562f8d3863061d5f096271f/civl.png",
  "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/db7bfa9d711b74568615ff1a05b09cb1/onlinemeded.png",
  "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/3e607eb0cee92297f0e98d60bc417269/unitednations.svg",
  "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/a56b23ef1243eb95645caebccfbb6175/unesco.webp",
  "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/7927bc19dc405da4e354cae75189f95c/unsdsn.svg",
  "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/faff4e1943bf1e589221a31b8f6f1e5c/qct-logo.png",
  "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/ad58a40efc608b74e388a1702fe3dd16/marketerhire.png",
  "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/e10ba02f70002781b6ac828f29674caa/fyli.png",
  "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/50b6946ef46e35ede87f9132ab3d9a18/fiesta.png",
  "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/fb11da504bb1ad21a7d9595da1b43c3f/of.png",
  "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/fa76154f4abfc6f0ad7fffa10a2de689/within.png",
  "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/0d8f0c71a27a185cbe5e9acbb99f3c1b/pbb.png",
  "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/72d9168a6fd6cc282119f19119fd91de/keysight.png",
  "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/d82e0c039570a773de97d014f7988b83/cs.png",
];


function Logos() {
  return (
    <Wrapper className="App">
      <h2>Clients who went <b>Interactive!</b></h2>
      <ScrollableLogoSection logos={logos} />
    </Wrapper>
  );
}

export default Logos;