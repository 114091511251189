import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Interactive Widgets for Websites",
  },
  "header": {
    "title": <>Interactive <span>Widgets</span> for Websites</>,
    "description": "Boost your websites engagement and conversion with Interactive Content.",
    "youtube": "https://www.youtube.com/embed/941kI-S6ZhE"
  },
  "main": [
    {
      "title": "Make a Widget",
      "description": <></>,
      "loom": ""
    },
    {
      "title": "Embed a Widget",
      "description": <></>,
      "loom": ""
    },
    {
      "title": "See Results",
      "description": <></>,
      "loom": ""
    },
  ],
}

const InteractiveWebsites = () => {
  return (
    <Template content={content} />
  )
};
export default InteractiveWebsites;
