import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Constants from '../../submodules/logictry_config/constants';

const Wrapper = styled.footer`
  padding: 2rem 2rem 6rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  > div:first-child {
    width: 100%;
    text-align: center;
    padding: 1rem 0 0;
    font-size: 1.5rem;
  }
  > div:nth-child(2) {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    > * {
      color: black;
      font-size: 1rem;
      cursor: pointer;
      text-decoration: none;
    }
    *:hover, *:focus, *:active {
      color: black;
      text-decoration: underline;
    }
  }
  > div:nth-child(3) {
    padding: 0 0 1rem;
  }
`;
const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: black;
  img {
    width: 20px;
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%);
  }
  a {
    padding: 0.5rem;
    display: flex;
  }
`;

const Footer = () => (
  <Wrapper>
    <div>
      Make Better Decisions Faster™
    </div>
    <SocialIcons>
      <a href={Constants.FacebookPage} target="_blank">
        <img src={'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/b50fbb8e66751aa50b8beb04184af1d8/facebook.svg'}></img>
      </a>
      <a href={Constants.InstagramPage} target="_blank">
        <img src={'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/d565bd027d94322e82543a12a7850608/instagram.svg'}></img>
      </a>
      <a href={Constants.TwitterPage} target="_blank">
        <img src={'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/4d7f9434b5858f055cb51aa23769b2d9/twitter.svg'}></img>
      </a>
      <a href={Constants.LinkedInPage} target="_blank">
        <img src={'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/cd307d91eb0e73bd82c66261718304aa/linkedin.svg'}></img>
      </a>
      <a href={Constants.YouTubePage} target="_blank">
        <svg style={{ width: 24, height: 24 }} fill="#2e3230" height="800px" width="800px" version="1.1" id="Capa_1" viewBox="0 0 49 49">
          <g>
            <g>
              <path d="M39.256,6.5H9.744C4.371,6.5,0,10.885,0,16.274v16.451c0,5.39,4.371,9.774,9.744,9.774h29.512
                c5.373,0,9.744-4.385,9.744-9.774V16.274C49,10.885,44.629,6.5,39.256,6.5z M47,32.726c0,4.287-3.474,7.774-7.744,7.774H9.744
                C5.474,40.5,2,37.012,2,32.726V16.274C2,11.988,5.474,8.5,9.744,8.5h29.512c4.27,0,7.744,3.488,7.744,7.774V32.726z"
                stroke="#2e3230" stroke-width="1.5"/>
              <path d="M33.36,24.138l-13.855-8.115c-0.308-0.18-0.691-0.183-1.002-0.005S18,16.527,18,16.886v16.229
                c0,0.358,0.192,0.69,0.502,0.868c0.154,0.088,0.326,0.132,0.498,0.132c0.175,0,0.349-0.046,0.505-0.137l13.855-8.113
                c0.306-0.179,0.495-0.508,0.495-0.863S33.667,24.317,33.36,24.138z M20,31.37V18.63l10.876,6.371L20,31.37z"
                stroke="#2e3230" stroke-width="1.5"/>
            </g>
          </g>
        </svg>
      </a>
      <a href={'https://logictry.com/@logictry'} target="_blank">
        <img src={'https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/f169b8a0c039f6d840ab43a14ff8d72d/logictry.svg'}></img>
      </a>
    </SocialIcons>
    <div>
      © Copyright <a href="https://logictry.com">Logictry</a>. All Rights Reserved
    </div>
  </Wrapper>
)
export default Footer;