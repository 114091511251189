import React from "react";
import Template from "../../components/Template";
import Sitemap from '../../components/Navbar/sitemap.json';

const content = {
  "meta": {
    "title": "Make Better Decisions Faster™",
  },
  "header": {
    "title": <>Make Better Decisions Faster™</>,
    "description": "At Logictry, we are on a mission to scale expertise and unlock better decisions in your personal life, in your professional life, and in the world.",
    "youtube": "https://www.youtube.com/embed/vvM32dMMxCY?si=ww0buaV576hyjxN8"
  },
  "main": [
    {
      "title": <>Your <span>Personal</span> Decisions</>,
      "description": "Become an expert faster by learning from our experts, and make better decisions faster!",
      "image": "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/3142263e697437b10ca035d86e828acb/logicapp.png",
      "link": Sitemap.LogicApp
    },
    {
      "title": <>Your <span>Business</span> Decisions</>,
      "description": "Scale your company's expertise and turn new hires into instant expert decision-makers!",
      "image": "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/26370f3083e00338fddf35056235cbf0/Screenshot%202024-02-02%20at%204.10.33%E2%80%AFPM.png",
      "link": Sitemap.LogicPlatform
    },
    {
      "title": <>The <span>World's</span> Decisions</>,
      "description": "Explore the global Logic movement supported by the United Nations!",
      "image": "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/a973d38d93f563b3b535abe8ea94e5de/Screenshot%202024-02-02%20at%204.14.35%E2%80%AFPM.png",
      "link": Sitemap.WorldLogicDay
    },
  ],
}

const MakeBetterDecisionsFaster = () => {
  return (
    <Template content={content} />
  )
};
export default MakeBetterDecisionsFaster;
