import React from "react";
import Template from "../../components/Template";
import Sitemap from '../../components/Navbar/sitemap.json';

const content = {
  "meta": {
    "title": "The Future of Thought Leadership is Interactive",
  },
  "header": {
    "title": <>The Future of Thought Leadership is <span>Interactive</span></>,
    "description": "Try our NEW Logic App™ and experience the difference with personalized actionable content",
    "image": "https://logictryuserfiles.s3.amazonaws.com/5cf13c4899397a33fd191007/3142263e697437b10ca035d86e828acb/logicapp.png",
    "cta": {
      "title": "See it live!",
      "link": "https://logictry.com/"
    }
  },
  "main": [
  ],
}

const LogicApp = () => {
  return (
    <Template content={content} />
  )
};
export default LogicApp;
