import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "AI-Powered Quizzes",
  },
  "header": {
    "title": <><span>AI-Powered</span> Quizzes</>,
    "description": "Leverage AI to create dynamic quizzes with automatically generated questions and answers, streamlining content creation and enhancing user engagement.",
    "app": "/apps/667ea2ca8f8df94c10bc0d8b"
  },
  "main": [
    {
      "title": "Grab Attention",
      "description": <><span>81%</span> of marketers agree that interactive content is more effective at grabbing people's attention than static content.</>,
      "app": "/openai-should-i-vote-republican-or-democrat"
    },
    {
      "title": <>Boost Engagement</>,
      "description": <><span>52.6%</span> higher engagement rates with interactive content compared to static content</>,
      "app": "/apps/666a11245320fa0e28ced65b"
    },
    {
      "title": "Repeat Visitors",
      "description": <><span>77%</span> of marketers agree that interactive content results in repeat visitors and multiple exposures.</>,
      "app": "/apps/667cd12b8f8df94c10bbd15e"
    },
  ],
  "caseStudies": [
    "the-advocates-political-survey-platform"
  ]
}

const Quizzes = () => {
  return (
    <Template content={content} />
  )
};
export default Quizzes;
